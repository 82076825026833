import React from 'react'
import { View } from 'react-native'
import COLORS from '../../utils/colors'
import { useTheme } from '../ThemeProvider/ThemeProvider'

export const UncheckedIcon = (props) => {
    const { theme } = useTheme()

    return (
        <View
            {...props}
            style={[
                {
                    width: 20,
                    height: 20,
                    borderRadius: theme?.spacing?.borderRadius ?? 5,
                    borderWidth: theme?.spacing?.borderWidth ?? 1,
                    borderColor: theme?.colors?.disabled ?? COLORS.COLOR_DISABLE_GREY,
                },
            ]}
        />
    )
}
