import React, { useState, useEffect } from 'react'
import { PressableProps, View } from 'react-native'
import { CheckBox, CheckBoxProps } from '@rneui/themed'
import { CheckBoxIconProps } from '@rneui/base/dist/CheckBox/components/CheckBoxIcon'
import { CheckedIcon } from './CheckedIcon'
import { IndeterminateIcon } from './IndeterminateIcon'
import { NestedCheckbox, NestedCheckboxProps } from './NestedCheckbox'
import { UncheckedIcon } from './UnCheckedIcon'
import { useTheme } from '../ThemeProvider/ThemeProvider'

export type CheckboxProps = Omit<CheckBoxProps, 'checked'> &
    PressableProps &
    Omit<CheckBoxIconProps, 'checked'> & {
        children?: undefined
        checked?: boolean
        indeterminate?: boolean
        nestedCheckboxes?: Omit<NestedCheckboxProps, 'handleNestedCheck'>[]
        customCheckedIcon?: React.ReactNode
        customUncheckedIcon?: React.ReactNode
        customIndeterminateIcon?: React.ReactNode
        checkedIconTestID?: string
        unCheckedIconTestID?: string
        indeterminateIconTestID?: string
    }

export function Checkbox(props: CheckboxProps) {
    const {
        style,
        onPress,
        checked,
        indeterminate,
        nestedCheckboxes,
        customCheckedIcon,
        customUncheckedIcon,
        customIndeterminateIcon,
        title,
        checkedIconTestID,
        unCheckedIconTestID,
        indeterminateIconTestID,
        ...rest
    } = props

    const { theme } = useTheme()

    const [internalChecked, setInternalChecked] = useState(false)
    const [nestedChecks, setNestedChecks] = useState(nestedCheckboxes || [])
    const [isIndeterminate, setIsIndeterminate] = useState(false)

    const [isChecked, setIsChecked] = useState(checked)

    useEffect(() => {
        const allChecked = nestedChecks.every((item) => item.checked)
        const someChecked = nestedChecks.some((item) => item.checked)
        setInternalChecked(allChecked)
        setIsIndeterminate(!allChecked && someChecked)
    }, [nestedChecks])

    const _style = style
        ? typeof style === 'function'
            ? style({ pressed: internalChecked })
            : style
        : {}

    const handleNestedCheck = (id: string) => {
        setNestedChecks((prev) =>
            prev.map((item) =>
                item.id === id ? { ...item, checked: !item.checked } : item,
            ),
        )
    }

    const handleParentCheck = () => {
        const newCheckedState = !internalChecked
        setInternalChecked(newCheckedState)
        setNestedChecks((prev) =>
            prev.map((item) => ({ ...item, checked: newCheckedState })),
        )
    }

    if (nestedCheckboxes?.length > 0) {
        return (
            <View>
                <CheckBox
                    title={title}
                    checkedIcon={
                        internalChecked ? (
                            customCheckedIcon ? (
                                <View>{customCheckedIcon}</View>
                            ) : (
                                <CheckedIcon testID={checkedIconTestID} />
                            )
                        ) : isIndeterminate ? (
                            customIndeterminateIcon ? (
                                <View>{customIndeterminateIcon}</View>
                            ) : (
                                <IndeterminateIcon
                                    testID={indeterminateIconTestID}
                                />
                            )
                        ) : (
                            <UncheckedIcon />
                        )
                    }
                    uncheckedIcon={
                        customUncheckedIcon ? (
                            <View>{customUncheckedIcon}</View>
                        ) : (
                            <UncheckedIcon testID={unCheckedIconTestID} />
                        )
                    }
                    textStyle={{ fontFamily: 'Arial' }}
                    checked={internalChecked || isIndeterminate}
                    containerStyle={[
                        {
                            backgroundColor:
                                theme?.colors?.background ?? 'transparent',
                        },
                        _style,
                    ]}
                    onPress={onPress ?? handleParentCheck}
                    size={20}
                    {...rest}
                />
                {nestedChecks.map((check) => (
                    <NestedCheckbox
                        key={check.id}
                        id={check.id}
                        title={check.title}
                        checked={check.checked}
                        handleNestedCheck={handleNestedCheck}
                        customCheckedIcon={check.customCheckedIcon}
                        customUncheckedIcon={check.customUncheckedIcon}
                        style={[{ paddingLeft: 40 }, _style]}
                    />
                ))}
            </View>
        )
    }

    return (
        <CheckBox
            title={title}
            checkedIcon={
                isChecked &&
                (indeterminate ? (
                    customIndeterminateIcon ? (
                        <View>{customIndeterminateIcon}</View>
                    ) : (
                        <IndeterminateIcon />
                    )
                ) : customCheckedIcon ? (
                    <View>{customCheckedIcon}</View>
                ) : (
                    <CheckedIcon />
                ))
            }
            uncheckedIcon={
                customUncheckedIcon ? (
                    <View>{customUncheckedIcon}</View>
                ) : (
                    <UncheckedIcon />
                )
            }
            textStyle={{ fontFamily: 'Arial' }}
            checked={isChecked}
            containerStyle={[
                { backgroundColor: theme?.colors?.background ?? 'transparent' },
                _style,
            ]}
            onPress={(e) => {
                setIsChecked(!isChecked)
                onPress?.(e)
            }}
            size={20}
            {...rest}
        />
    )
}
