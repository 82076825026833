import React from 'react'
import { Svg, Rect, Path } from 'react-native-svg'
import { useTheme } from '../ThemeProvider/ThemeProvider'

export function CheckedIcon(props) {
    const { theme } = useTheme()
    const primaryColor = theme?.colors?.primary || '#6200EA'
    const secondaryColor = theme?.colors?.secondary || '#fff'
    return (
        <Svg width={20} height={20} viewBox="0 0 20 20" fill="none" {...props}>
            <Rect width={20} height={20} rx={6} fill={primaryColor} />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.061 5.422a1 1 0 01.267 1.39l-5.085 7.5a1 1 0 01-1.525.155l-3.916-3.813A1 1 0 116.198 9.22l3.06 2.98 4.414-6.512a1 1 0 011.39-.267z"
                fill={secondaryColor}
            />
        </Svg>
    )
}

